<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        Organization Settings
      </v-card-title>
      <v-tabs v-if="subscription" show-arrows>
        <v-tab exact :to="{ name: 'settings' }">
          General
        </v-tab>
        <v-tab :to="{ name: 'subscription' }">
          Subscription
        </v-tab>
        <v-tab :to="{ name: 'billing' }">
          Billing
        </v-tab>
        <!-- <v-tab :to="{ name: 'team' }">
          Team
        </v-tab> -->
      </v-tabs>
      <v-fade-transition mode="out-in">
        <router-view
          :customer="customer"
          @refresh="getCustomerInfo"
        />
      </v-fade-transition>
    </v-card>
    <v-overlay v-if="loading">
      <card-loader />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Settings',
  data: () => ({
    loading: true,
    customer: null
  }),
  computed: {
    ...mapState(['subscription']),
    ...mapGetters(['organization'])
  },
  beforeMount() {
    if (!this.organization.stripeCustomerId) {
      this.$route.name !== 'settings' && this.$router.push({ name: 'settings' })
    }
  },
  mounted() {
    this.getCustomerInfo()
  },
  methods: {
    async getCustomerInfo() {
      this.loading = true
      const { stripeCustomerId } = this.organization
      if (!stripeCustomerId) {
        this.loading = false
        return
      }
      const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/customer`, {
        params: {
          stripeCustomerId
        }
      })
      this.customer = data
      if (data.subscriptions && data.subscriptions.data && data.subscriptions.data.length) {
        this.$store.dispatch('setSubscription', data.subscriptions.data[0])
      }
      this.loading = false
    }
  }
}
</script>
